import styled, { css } from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import { FiPlusCircle as DefaultPlusCircle } from 'react-icons/fi';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as TourIcon } from 'assets/icons/video.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { ReactComponent as HeartIcon } from 'assets/icons/heart.svg';
import { ReactComponent as FlashIcon } from 'assets/icons/flash.svg';
import { ReactComponent as Picture } from 'assets/icons/picture.svg';
import DefaultMap from 'components/Map';
import {
  H3,
  H5,
  Overline,
  Small,
  BodyMedium,
  Body,
  LinkText,
} from 'components/Typography';
import DefaultBookingCard from 'components/BookingCard';
import DefaultSpace from 'components/Card/Space';
import DefaultButton from 'components/Button';
import DefaultLikeButton from 'components/LikeButton';
import DefaultMarker from 'components/Marker';
import DefaultButtonBack from 'components/ButtonBack';
import DefaultMaxContent from 'components/MaxContent';
import DefaultCarousel from 'components/Carousel';
import { from } from 'styles/media';

export const Clickable = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

export const BookingCard = styled(DefaultBookingCard)`
  margin: 0 auto;
  ${from.tablet} {
    display: block;
  }
`;

export const Container = styled.div`
  padding: 1rem;
`;

export const BothContainers = styled.div`
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div<{ $colored?: boolean }>`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.ebonyClay};
  margin-bottom: 5rem;
`;

export const Address = styled.div`
  display: flex;
  flex-direction: column;
  ${from.laptop} {
    align-items: center;
    flex-direction: row;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: flex-start;

  ${from.tablet} {
    margin-top: 1.5rem;
    align-items: flex-end;
  }
`;

export const Grid = styled.div`
  display: grid;
  ${from.mobile} {
    grid-template-columns: 1fr;
  }
  ${from.tablet} {
    grid-template-columns: 1fr 25.6875rem;
  }
`;

export const Row = styled(DefaultMaxContent)`

  padding: 0;

  ${from.tablet} {
    padding: 0.25rem 1rem;
  }
`;

export const FinalRow = styled(DefaultMaxContent)`
  margin-top: 4rem;
`

export const Title = styled(H3)`
  margin-bottom: 1rem;
  margin-top: 1.125;
`;

export const MaxWidth = styled.div`
  max-width: 45.4375rem;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray300};
  margin: 3rem 0;
`;
export const SeparatorMobile = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray300};
  margin: 1.9375rem 0;
  ${from.tablet} {
    display: none;
  }
`;

export const MobileImageSeparator = styled.div`
  height: 220px;
  ${from.tablet} {
    display: none;
  }
`;

export const RowRelations = styled.div`
  width: 100%;
  margin: auto;
  padding: 0 1rem;
  max-width: 80rem;
`;

export const RowTextContainer = styled.div<{ $bottomAlign?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ $bottomAlign }) =>
    $bottomAlign ? 'flex-end' : 'baseline'};
  flex-wrap: wrap;
  margin-bottom: 0rem;
`;

export const RowSubtitle = styled(H5)`
  font-weight: 600;
  margin-bottom: 1rem;
  :before {
    display: block;
    content: '';
    height: 6.25rem;
    margin: -6.25rem 0 0;
  }
`;

export const RowText = styled(Small)`
  margin: 1rem 0 3rem;
  font-size: 0.875rem;
`;

export const Link = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.ebonyClay};
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const LinkTextUppercase = styled(Overline)`
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  grid-gap: 2rem;
  margin-top: 1rem;
  width: 100%;
  height: 100%;

  ${from.tablet} {
    grid-gap: 1.5rem;
  }
`;

export const Carousel = styled(DefaultCarousel)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  margin-top: 5rem;
  height: 16.25rem;
`;

export const GalleryGrid = styled.div`
  display: grid;
  max-height: 25rem;
  ${from.mobile} {
    grid-template-columns: 1fr;
  }
  ${from.tablet} {
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
    margin: auto;
    margin-bottom: 2.625rem;
    margin-top: 1.5rem;
    position: relative;
    width: 100%;
    max-width: 80rem;
    padding: 0 1rem;
  }
`;

export const BigImage = styled.div`
  ::after {
    border: 0px solid rgb(0, 0, 0);
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  img {
    object-fit: cover;
    vertical-align: bottom;
    height: 100%;
    width: 100%;
    ${from.tablet} {
      border-radius: 0.3125rem;
    }
  }
`;

export const SmallGrid = styled.div`
  display: none;
  ${from.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
  }
`;

export const SmallImage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 0px;
  img {
    object-fit: cover;
    vertical-align: bottom;
    height: 100%;
    width: 100%;
    border-radius: 0.3125rem;
  }
`;

export const Map = styled(DefaultMap)`
`;

export const MapContainer = styled.div`
  padding: 0.5rem 0;
  z-index: 99;
  width: 100%;
  height: 30rem;
  margin-bottom: 2rem;
`;

export const Marker = styled(DefaultMarker)``;

export const LinkIcon = styled(DefaultPlusCircle)`
  margin-right: 0.5813rem;
`;

export const IconShare = styled(ShareIcon)`
  margin-right: 0.5813rem;
  width: 1.5rem;
  height: 1.5rem;
`;

export const IconHeart = styled(HeartIcon)`
  margin-right: 0.5813rem;
  width: 1.5rem;
  height: 1.5rem;
`;

export const FlashHeart = styled(FlashIcon)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const IconTour = styled(TourIcon)`
  color: ${({ theme }) => theme.colors.royalBlue};
  width: 1.5rem;
  height: 1.5rem;
`;

export const IconPicture = styled(Picture)`
  color: ${({ theme }) => theme.colors.royalBlue};
  width: 1.5rem;
  height: 1.5rem;
`;

export const ButtonBack = styled(DefaultButtonBack)<{ $isWhite: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 6rem;
  margin-left: 1rem;
  z-index: 1;

  ${({ $isWhite }) =>
    $isWhite &&
    css`
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    `}

  ${from.tablet} {
    position: relative;
    margin-top: 1.5rem;
    margin-left: 0;
    path {
      fill: ${({ theme }) => theme.colors.royalBlue};
    }
  }
`;

export const IconCheck = styled(CheckIcon)`
  color: ${({ theme }) => theme.colors.royalBlue};
  margin-right: 0.875rem;
  width: 1.5rem;
  height: 1.5rem;
`;

export const Service = styled.div`
  display: flex;
  height: 2.5rem;
  align-items: center;
`;

export const Characteristic = styled.div`
  display: flex;
  height: 2.5rem;
  align-items: center;
`;

export const ServiceWrapper = styled.div`
  display: grid;
  ${from.mobile} {
    grid-template-columns: 1fr;
  }
  ${from.tablet} {
    grid-template-columns: 1fr 1fr;
    column-gap: 3.125rem;
  }
`;

export const Button = styled(DefaultButton)``;

export const GalleryButton = styled(Button)`
  width: 12.5rem;
  position: absolute;
  margin-right: 2.1875rem;
  margin-bottom: 1.875rem;
  bottom: 0;
  right: 0;
`;

export const TourButton = styled(Button)`
  margin-top: 2rem;
  margin-bottom: 3rem;
`;

export const TextBold = styled(BodyMedium)``;

export const Text = styled(Body)``;

export const Location = styled(Body)`
  color: ${({ theme }) => theme.colors.gray700};
`;

export const Web = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.gray700};
  text-decoration: underline;
  margin-top: 1rem;
  display: block;
  ${from.tablet} {
    margin-top: 0;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Share = styled(LinkText)`
  color: ${({ theme }) => theme.colors.black};
  margin-right: 0.625rem;
  text-decoration: underline;
  display: none;

  ${from.tablet} {
    display: block;
  }
`;

export const BookingButtonWrapper = styled(LinkText)`
  background-color: ${({ theme }) => theme.colors.white};
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 1rem;
  z-index: 1;
  box-shadow: 0px -5px 20px rgba(51, 46, 191, 0.1);
  ${from.laptop} {
    display: none;
  }
`;

export const SpaceCard = styled(DefaultSpace)``;

export const LikeButton = styled(DefaultLikeButton)`
  display: flex;
  align-items: center;
`;

export const Categories = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2.5rem;
`;

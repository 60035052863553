import styled, { css } from 'styled-components';
import DefaultStepper from 'components/Stepper';
import MaxContent from 'components/MaxContent';
import DefaultButtonBack from 'components/ButtonBack';
import BookingCard from 'components/Card/BookingDetails';
import {
  H4,
  H5,
  P,
  BodyMedium,
  Small,
} from 'components/Typography';
import { from } from 'styles/media';
import { ReactComponent as PaperPlane } from 'assets/icons/paper-plane.svg';
import {ReactComponent as checkIcon} from 'assets/icons/circle-check.svg';
import {ReactComponent as messageCircle, ReactComponent as Info } from 'assets/icons/message-circle-outline.svg';
import { Link as DefaultLink } from 'react-router-dom';
import DefaultTimer from 'components/Timer';
import DefaultButton from 'components/Button';
import DefaultRadioSelector from 'components/RadioSelector';
import { FiArrowRight } from 'react-icons/fi';
import DefaultInvoices from 'components/Invoices';
import DefaultInput from 'components/Input';

export const Container = styled(MaxContent)`
  padding: 1rem;
  margin-bottom: 6.5rem;
  ${from.mobile} {
    padding-top: 2.5rem;
  }
`;


export const BothContainers = styled.div`
`

export const ArrowIcon = styled(FiArrowRight)`
  width: 1.3125rem;
  height: 1.3125rem;
`;

export const Stepper = styled(DefaultStepper)`
  margin-bottom: 5rem;
  ${from.tablet}{
    margin-bottom: 0;
  };
`;

export const Content = styled.div`
  margin-top: 2.375rem;
  margin-bottom: 1.755rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  ${from.laptop} {
    flex-direction: row;
  }
`;

export const RadioContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  ${from.mobile} {
    flex-direction: row;
  }
`;

export const Form = styled.form`
`;

export const SectionText = styled.div`
  width: 100%;
  margin: 2rem 0;
  max-width: 46.0625rem;
`;

export const Section = styled.div`
  padding-bottom: 1rem;
  flex-direction: row;
  max-width: none;
  margin: 0 auto;
  ${from.laptop} {
    width: 100%;
    max-width: 46.0625rem;
  }
`;
export const ModalSection = styled.div`
  padding: 1rem;
`

export const ButtonSection = styled.div`
  display: none;
  ${from.laptop} {
    display: block;
    width: 100%;
    max-width: 46.0625rem;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ButtonBack = styled(DefaultButtonBack)`
  margin-right: 1.5rem;
`;

export const Button = styled(DefaultButton)`
  vertical-align: middle;
  border-radius: 0.3125rem;
  margin-bottom: 0.4rem;
  padding: 1rem;
`;

export const DivHidden = styled.div`
  visibility:hidden;
`

export const moreInfoButton = styled(DefaultButton)`
  background-color: none;
`;

export const Title = styled(H4)``;

export const Card = styled(BookingCard)`
`;

export const InfoCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
  border-radius: 0.3125rem;
  background-color: ${({ theme }) => theme.colors.gray200};
  ${from.laptop}{
    padding: 2rem;
  }
`;

export const CenteredContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

export const DoubleTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  ${from.mobile}{
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const InfoTitle = styled(H5)<{ $isBlue?: boolean }>`
  color: ${({ theme }) => theme.colors.ebonyClay};
  ${({ $isBlue }) =>
    $isBlue &&
    css`
      color: ${({ theme }) => theme.colors.royalBlue};
    `}
  padding:0 0 0.5rem;
`;

export const TitleSection = styled(InfoTitle)`
  margin-top: 5rem;
`;

export const InfoText = styled(P)`
  margin-top: 1rem;
  :first-child {
    margin-top: 2rem;
  }
`;
export const Select = styled.select`
color: ${({ theme }) => theme.colors.gray700};
  outline: none;
  font-size: 0.875rem;
  line-height: 1.225rem;
  appearance: none;
  background-color: ${({ theme }) => theme.colors.gray200};
  border: 0.125rem solid ${({ theme }) => theme.colors.white};
  border-radius: 0.3125rem;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  padding: 0.875rem 0.75rem;
  transition: 0.3s;
  &:focus-within {
    border: 0.125rem solid ${({ theme }) => theme.colors.royalBlue};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray300};
  }
  :disabled {
    color: ${({ theme }) => theme.colors.gray400};
  }
`
export const Option = styled.option`
`;
export const Input = styled(DefaultInput)`
  width: 100%;
`;
export const LinkText = styled(DefaultLink)`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 140%;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.black};
  animation-timing-function: ease-out;
  animation-duration: 300ms;
  border: 0;
  background: none;
  padding: 0;
  outline: none;
  margin-top: 1.5rem;
  cursor: pointer;
`;
export const InfoTextBottom = styled(P)`
  vertical-align: middle;
  margin-right: 2%;
`;


export const BoldText = styled.span`
  font-size: 1rem;
  line-height: 1.625rem;
  font-weight: 600;
`;

export const Timer = styled(DefaultTimer)`
  margin-top: 2rem;
`;

export const Link = styled(DefaultLink)`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 140%;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.black};
  animation-timing-function: ease-out;
  animation-duration: 300ms;
  border: 0;
  background: none;
  padding: 0;
  outline: none;
  margin-top: 1.5rem;
  cursor: pointer;
`;

export const InfoLinkText = styled(LinkText)``;

export const PaperPlaneIcon = styled(PaperPlane)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;

  path {
    fill: ${({ theme }) => theme.colors.royalBlue};
  }
`;

export const CheckIcon = styled(checkIcon)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;

  path {
    fill: ${({ theme }) => theme.colors.royalBlue};
  }
`;

export const InfoIcon = styled(Info)`
  width: 1.5rem;
  height: 1.5rem;

  path {
    fill: ${({ theme }) => theme.colors.royalBlue};
  }
`;

export const MessageCircle = styled(messageCircle)`
  width: 1.5rem;
  height: 1.5rem;
  padding-bottom: 4px;
  margin-bottom:4px;
  path {
    fill: ${({ theme }) => theme.colors.royalBlue};
  }
`;

export const ButtonContainer = styled.div`
  display: none;
  ${from.mobile} {
    display: block;
  }
  width: 19.25rem;
  margin-top: 3.5rem;
`;

export const OptionCard = styled.div`
  width: 100%;
  max-height: 6.125rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.black};
  box-sizing: border-box;
  border-radius: 0.3125rem;
  margin-top: 1.5rem;
`;

export const RadioSelector = styled(DefaultRadioSelector)``;

export const OptionSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
`;

export const SectionBottom = styled.div<{ hideSection?: boolean }>`
  margin-top: 2rem;
  ${({ hideSection }) =>
    hideSection &&
    css`
      display:none;
    `}
`;

export const OptionTitle = styled(BodyMedium)``;

export const OptinoSubtitle = styled(Small)`
  margin-top: 0.5rem;
`;

export const Invoices = styled(DefaultInvoices)``;

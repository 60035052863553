import React, { FC } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import {
  Container,
  Body,
  Form,
  Input,
  Button,
  LegalText,
  Flex,
  Checkbox, LegalLink,
} from './styles';
import useConnect from './connect';
import { Error } from '../../../../components/Input/styles';
import {Props} from "./types";

const CallbackRequest: FC<Props> = ({ handleClose, open, ...props }) => {
  const {
    initialValues,
    validationSchema,
    handleSubmit,
    loading,
  } = useConnect({ handleClose });

  if (loading) return <>Loading...</>;

  return (
    <>
      <Container title="Solicitud de Llamada" open={open} handleClose={handleClose} {...props}>
        <Body>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            <Form noValidate>
              <Field type="text" name="name" as={Input} label="Nombre" />
              <Field type="tel" name="phone" as={Input} label="Teléfono" />
              <Flex>
                <Field type={'checkbox'} name="check" as={Checkbox} />
                <LegalText>
                  Aceptas nuestros&nbsp;
                  <LegalLink to="/terms-and-conditions">
                    Términos y condiciones
                  </LegalLink> y&nbsp;
                  <LegalLink to="/privacy">Política de privacidad</LegalLink>
                </LegalText>
              </Flex>
              <ErrorMessage component={Error} name={'check'} />

              <Button variant="primary" fullWidth type="submit">
                Solicitar
              </Button>
            </Form>
          </Formik>
        </Body>
      </Container>
    </>
  );
};

export default CallbackRequest;

import React from 'react';
import styled from 'styled-components';
import {useModalContext} from "../../../provider/Modal";

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #4640DF;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  z-index: 1000;
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const SearchSpace = styled.div`
  text-align: center;
  cursor: pointer;
  line-height: 1.5rem;
  font-size: 0.8rem;
  text-decoration: none;
  text-underline: none;
  color: white;

  span {
    //color: #4640DF;
    font-size: 1rem;
    font-weight: bold;
  }
`;

const MobileFooter: React.FC = () => {
  const {
    handleOpenModalCallback
  } = useModalContext();

  return (
    <FooterContainer>
      <SearchSpace onClick={handleOpenModalCallback}>
        ¿Te buscamos el espacio? <br/>
        Servicio <span>GRATUITO</span>
      </SearchSpace>
    </FooterContainer>
  );
};

export default MobileFooter;

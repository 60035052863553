import React, { createContext, useState, FC, useContext } from 'react';

type Modal = { status: boolean; modal: string };

type ContextState = {
  open: Modal;
  handleCloseModal: () => void;
  handleOpenModalLogin: () => void;
  handleOpenModalRegister: () => void;
  handleOpenModalCallback: () => void;
  handleOpenModalForgot: () => void;

  handleOpenModalTransfer: () => void;
};

const defaultValue = {
  open: { status: false, modal: '' },
  handleCloseModal: () => {},
  handleOpenModalLogin: () => {},
  handleOpenModalRegister: () => {},
  handleOpenModalForgot: () => {},
  handleOpenModalCallback: () => {},
    handleOpenModalTransfer: () => {},
};

const ModalContext = createContext<ContextState>(defaultValue);

export const useModalContext = () => useContext(ModalContext);

const ModalProvider: FC = ({ children }) => {
  const [open, setOpen] = useState({ status: false, modal: '' });

  const handleOpenModalLogin = () => setOpen({ status: true, modal: 'login' });

    const handleOpenModalTransfer = () => setOpen({ status: true, modal: 'login' });

  const handleOpenModalRegister = () =>
    setOpen({ status: true, modal: 'register' });

  const handleOpenModalForgot = () =>
    setOpen({ status: true, modal: 'forgot' });

  const handleOpenModalCallback = () =>
    setOpen({ status: true, modal: 'callback' });

  const handleCloseModal = () => setOpen({ status: false, modal: '' });

  return (
    <ModalContext.Provider
      value={{
        open,
        handleOpenModalLogin,
        handleOpenModalRegister,
        handleOpenModalForgot,
        handleOpenModalCallback,
        handleCloseModal,
          handleOpenModalTransfer,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;

import React, { FC, memo } from 'react';
import PayModal from 'components/PayButtonModal';
import CancelButtonModal from 'components/CancelButtonModal';
import PayButton from 'components/PayButton';
import {
  Component,
  CarouselWrapper,
  Carousel,
  Content,
  Section,
  BodyText,
  BodyMediumText,
  SmallText,
  PSemiText,
  Title,
  Divider,
  Row,
  LinkText,
  Column,
  AvatarIcon, ButtonSection,
} from './styles';
import { Props } from './types';
import PaymentButton from "../../../containers/Private/Booking/Payment/PaymentButton";
import TransferButton from "../../../containers/Private/Booking/Payment/TransferButton";

const IVA = 0.21;

const BookingDetails: FC<Props> = ({
  id,
  spaceName,
  location,
  images,
  dates,
  capacity,
  pricePerDay,
  totalToPay = 0,
  daysCount,
  insurrancePrice,
  bookingPrice,
  finalPayment,
  firstPayment,
  allowAllPayment = false,
  allowCancel = false,
  percentage,
  priceAprox,
  payButtons = false,
  IVAOption = false,
  bookingPriceDetails = false,
  payEverything = false,
  Reservation = false,
  seeFinalPayment = false,
  cancelOptions = false,
  cancellationInsurance = true, transferButton,
    isTransfered,
  ...props
}) => {

  return (
    <Component {...props}>
      <CarouselWrapper>
        <Carousel images={images} />
      </CarouselWrapper>
      <Content>
        <Section>
          <Title>{spaceName}</Title>
          <BodyText>{location}</BodyText>
        </Section>

        <Section>
          <Divider />
          <Section>
            <BodyMediumText>Fechas</BodyMediumText>
            <SmallText>{dates}</SmallText>
          </Section>
          {capacity && (
            <Section $top>
              <BodyMediumText>Aforo</BodyMediumText>
              <SmallText><AvatarIcon/>{capacity}</SmallText>
            </Section>
          )}
        </Section>

        {bookingPrice && (
          <Section>
             <Divider/>
                <Section>
                  <Row $top>
                    <BodyMediumText>{bookingPrice} x {daysCount} días</BodyMediumText>
                    <BodyMediumText>{totalToPay}€</BodyMediumText>
                  </Row>
                  <Row $top>
                  <PSemiText>Total</PSemiText>
                  <PSemiText>{totalToPay}€</PSemiText>
                  </Row>
                </Section>
            {Reservation && (
              <>
                <Divider/>
                <Row $top>
                  <PSemiText>Reserva</PSemiText>
                  <PSemiText>{(bookingPrice/10).toFixed(2)}€</PSemiText>
                </Row>
                <Row $mediumTop>
                  <SmallText>{percentage}% del total</SmallText>
                </Row>
              </>
            )}
            
            {bookingPriceDetails && (
              <Section>
                <Row $top>
                  <SmallText>Precio</SmallText>
                  <SmallText>{bookingPrice - bookingPrice * IVA}€</SmallText>
                </Row>
                <Row $top>
                  <SmallText>Impuestos (21% IVA)</SmallText>
                  <SmallText>{bookingPrice * IVA}€</SmallText>
                </Row>
              </Section>
            )
            }
           
            {allowAllPayment && (
              <>
                <div style={{ marginTop: '1.5rem' }} />
                <PayModal
                  buttonCaption={`Realizar transferencia (${bookingPrice}€)`}
                  variant="primary"
                  id={id}
                  price={bookingPrice}
                  isPrebooking
                />
              </>
            )}
          </Section>
        )}

        {finalPayment && (
          <Section>
            <Divider />
            <Row $top>
              <PSemiText>Resto del pago</PSemiText>
              <PSemiText>{finalPayment}€</PSemiText>
            </Row>
            <Row $top>
              <SmallText>Precio</SmallText>
              <SmallText>{finalPayment - finalPayment * IVA}€</SmallText>
            </Row>
            <Row $top>
              <SmallText>Impuestos (21% IVA)</SmallText>
              <SmallText>{finalPayment * IVA}€</SmallText>
            </Row>
          </Section>
        )}

        {seeFinalPayment && (
          <Section>
          <Divider />
          <Row $top>
            <PSemiText>Pago final</PSemiText>
            <PSemiText>{totalToPay}€</PSemiText>
          </Row> 
          {IVAOption && (
            <Section>
              <Row $top>
                <SmallText>Precio</SmallText>
                <SmallText>{totalToPay - totalToPay * IVA}€</SmallText>
              </Row>
              <Row $top>
                <SmallText>Impuestos (21% IVA)</SmallText>
                <SmallText>{totalToPay * IVA}€</SmallText>
              </Row>
            </Section>
          )}
        </Section>
        )}
        {priceAprox && (
          <Section>
            <Divider />
            <Row $top>
              <PSemiText style={{ color: 'gray', textTransform: 'uppercase' }}>
                Recuerda que este precio es aproximado. Nos pondremos en
                contacto contigo en menos de 48 horas con el presupuesto final
              </PSemiText>
            </Row>
          </Section>
        )}

        { transferButton &&
            (
                <Section>
                  <Divider/>
                  <Column $height>
                    <ButtonSection>
                      <TransferButton />
                    </ButtonSection>
                  </Column>
                </Section>
            )
        }
        {payButtons && (

          <Section>
            <Divider/>
            <Column $height>
              <ButtonSection>
                {payEverything ?  (
                    <PaymentButton id={id}/>
                ) : (
                    <PayButton id={id}/>
                )
                }
              </ButtonSection>

            </Column>
          </Section>

        )

        }
        {allowCancel && (
          <Section>
            <Divider />
            {cancelOptions ? (
              <>
                <CancelButtonModal id={id} />
                <br/>
                <LinkText to="/cancellation-policies">
                  Opciones de cancelación
                </LinkText>
              </>

            ) : (
              <CancelButtonModal id={id} />
            )}
          </Section>
        )}
      </Content>
    </Component>
  );
};

export default memo(BookingDetails);

import React  from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import {
  QueryParamProvider,
  ExtendedStringifyOptions,
  transformSearchStringJsonSafe,
} from 'use-query-params';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import GlobalStyle from 'styles/GlobalStyle';
import Root from 'containers/Root';
import theme from 'styles/themes';
import client from 'graphql/client';
import ScrollToTop from 'components/ScrollToTop';
import ModalProvider from 'provider/Modal';
import Hotjar from '@hotjar/browser';
import mixpanel from 'mixpanel-browser';

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

const siteId = 4994438;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);
mixpanel.init('471f77a33fb45c7f81c645b13c035452', { debug: true, track_pageview: true, persistence: 'localStorage' });

const App: React.FC = () => {
  // const [isLogged, setIsLogged] = useState(
  //   localStorage.getItem('isLogged') === 'true'
  // );

  // useEffect(() => {
  //   if (isLogged) return;
  //   const password = prompt('Password', '');
  //
  //   if (password === 'patata') {
  //     setIsLogged(true);
  //     localStorage.setItem('isLogged', 'true');
  //   }
  // }, [isLogged, setIsLogged]);

  // if (!isLogged) {
  //   return <div />;
  // }

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <QueryParamProvider
            ReactRouterRoute={Route}
            stringifyOptions={queryStringifyOptions}
          >
            <ModalProvider>
              <ScrollToTop />
              <GlobalStyle />
              <Root />
            </ModalProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;

import React, {useEffect} from 'react';
import { Formik, Form } from 'formik';
import Button from 'components/Button';
import useConnect from '../../../../components/PayButton/connect';
import {Props} from "../../../../components/PayButton/types";
import {CenteredContainer, DivHidden} from "../styles";
import SavePaymentIcon from 'assets/icons/pago-seguro.png';

const PaymentButton = ({ id, isTransfered }: Props) => {
  const {
      handleSubmitPayment,
    initialValues,
    data
  } = useConnect({ id });

  useEffect(
      ()=>{
        const inputRef = document.getElementById("redsysButton");
        if (data && inputRef) {
          inputRef.click()
        }
      }
  )
  return (
      <>
    <Formik
      onSubmit={handleSubmitPayment}
      initialValues={initialValues}
    >
      <Form noValidate style={{ height: '100%', width: '100%' }}>
          <Button disabled={isTransfered}
            type="submit"
            size="big"
            fullWidth
            variant="primary"
          >
              Pagar reserva
          </Button>
          <CenteredContainer>
            <img height="50" src={SavePaymentIcon} alt="Pago Seguro"/>
          </CenteredContainer>
      </Form>
    </Formik>
          <DivHidden>
              {data && (
                  <form name="from" action="https://sis.redsys.es/sis/realizarPago" method="POST">
                      <input type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1"/>
                      <input type="hidden" name="Ds_MerchantParameters" value={data.data.payBooking.data.merchantParameters}/>
                      <input type="hidden" name="Ds_Signature" value={data.data.payBooking.data.signature}/>
                      <Button
                          type="submit"
                          id="redsysButton"
                          variant="hidden"
                      >
                          Pagar reserva
                      </Button>
                      <CenteredContainer>
                        <img height="50" src={SavePaymentIcon} alt="Pago Seguro"/>
                      </CenteredContainer>
                  </form>
              )
              }
          </DivHidden>

      </>
  );
};

export default PaymentButton;

import { useCallback, useState } from 'react';
import { FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { initialValues, validationSchema } from './constants';

const REQUEST_CALLBACK = gql`
  mutation RequestCallback($input: CallbackRequestInput!) {
    requestCallback(input: $input) {
      message
      error
    }
  }
`;

type FormValues = typeof initialValues;

type Args = {
  handleClose: () => void;
};

const useConnect = ({ handleClose }: Args) => {
  const [requestCallback, { loading }] = useMutation(REQUEST_CALLBACK);
  const [success, setSuccess] = useState(false);

  const closeSuccess = () => {
    setSuccess(false);
  };

  const handleSubmit = useCallback(
    async (
      values: FormValues,
      { setErrors, setFieldError }: FormikHelpers<FormValues>
    ) => {
      try {
        delete values['check'];
        const response = await requestCallback({ variables: { input: values } });
        if (response.data.requestCallback.error) {
          setFieldError('phone', response.data.requestCallback.error);
        } else {
          setSuccess(true);
          handleClose();
        }
      } catch (err) {
        console.error('Error:', err);
        setFieldError('phone', 'Error al enviar la solicitud.');
      }
    },
    [requestCallback, handleClose]
  );

  return {
    loading,
    handleSubmit,
    initialValues,
    validationSchema,
    success,
    closeSuccess
  };
};

export default useConnect;

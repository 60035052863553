import React from 'react';
import {Container} from "./styles";

const RequestSpace = () => {
    return (
        <Container>request space form</Container>
    );
};

export default RequestSpace;

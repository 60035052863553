import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import {
  Logo,
  Box,
  Icon,
  Clickable,
  LoginModal,
  RegisterModal,
  ForgotPassword,
  Section,
} from '../styles';
import { Props } from '../types';
import useConnect from './connect';
import Menu from './Menu';
import useRootConnect from '../connect';
import CallBackModal from "../../Modals/CallBackModal";

const MobileTopbar: FC<Props> = ({
  isVisible,
  isAuth,
  allowSearch,
  ...props
}) => {
  const { openMenu, handleCloseMenu, handleOpenMenu } = useConnect();
  const {
    open,
    handleOpenModal,
    handleCloseModal,
    handleOpenModalRegister,
    handleOpenModalForgot,
  } = useRootConnect();

  return (
    <>
      <Container $isVisible={isVisible} {...props}>
        <Link to="/">
          <Logo />
        </Link>

        <Section>
          <Clickable onClick={handleOpenMenu}>
            {isAuth ? (
              <Box>
                <Icon />
              </Box>
            ) : (
              <Icon />
            )}
          </Clickable>
        </Section>
      </Container>

      <LoginModal
        open={open.status && open.modal === 'login'}
        handleClose={handleCloseModal}
        goToRegister={handleOpenModalRegister}
        goToForgot={handleOpenModalForgot}
      />
      <RegisterModal
        open={open.status && open.modal === 'register'}
        handleClose={handleCloseModal}
        goToLogin={handleOpenModal}
      />
      <ForgotPassword
        open={open.status && open.modal === 'forgot'}
        handleClose={handleCloseModal}
      />
      <CallBackModal
        open={open.status && open.modal === 'callback'}
        handleClose={handleCloseModal}
      />

      <Menu
        isAuth={isAuth}
        openMenu={openMenu}
        handleOpenModal={handleOpenModal}
        handleCloseMenu={handleCloseMenu}
      />
    </>
  );
};

export default memo(MobileTopbar);

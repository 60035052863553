import React, { FC } from 'react';
import useConnect from './connect';
import {Container, Select, DatePicker, Button, SearchIcon, SearchSpace} from './styles';
import { Props } from './types';
import {useModalContext} from "../../../provider/Modal";

const Search: FC<Props> = ({ showLabels, showSearchSpace, ...props }) => {
  const {
    currentCity,
    handleCityChange,
    cityOptions,
    to,
    from,
    handleFromChange,
    handleToChange,
    goToSearch,
  } = useConnect();

  const {
    handleOpenModalCallback
  } = useModalContext();

  return (
    <Container {...props}>
      <Select
        label={showLabels ? 'Ubicación' : undefined}
        value={currentCity}
        onChange={handleCityChange}
        options={cityOptions}
      />
      <DatePicker
        to={to}
        from={from}
        toHandleChange={handleToChange}
        fromHandleChange={handleFromChange}
        labelLeft={showLabels ? 'Entrada' : undefined}
        labelRight={showLabels ? 'Salida' : undefined}
      />
      <Button className="search-btn" rightIcon={<SearchIcon />} isCentered onClick={goToSearch}>
        Buscar
      </Button>
      { showSearchSpace ? (
        <SearchSpace onClick={handleOpenModalCallback}>
          ¿Te buscamos el espacio? <br/>
          Servicio <span>GRATUITO</span>
        </SearchSpace>
      ) : '' }

    </Container>
  );
};

export default Search;

import React, { FC } from 'react';
import {
  Button,
  Container,
  DatePicker,
  Divider,
  Header,
  Indicator,
  Info,
  Message,
  Title,
  Total,
  Wrapper,
  Link,
} from './styles';
import { Props } from './types';

const BookingCard: FC<Props> = ({
  data,
  from,
  to,
  price,
  fromHandleChange,
  toHandleChange,
  availability,
  handleSubmit,
  values,
  prices,
  showPriceAndConditions,
  ...props
}) => {
  return (
    <Container {...props}>
      <Wrapper>
        <Header>
          <Title>Reserva</Title>
        </Header>

        <DatePicker
          isColumn
          from={from}
          to={to}
          price={price}
          fromHandleChange={fromHandleChange}
          toHandleChange={toHandleChange}
          labelLeft="Entrada"
          labelRight="Salida"
          prices={prices}
        />

        {availability?.available && (
          <>
            <Info>
              <Divider />

              <Indicator>
                <Total>Precio</Total>
                <Total>
                  {availability?.canInstantBooking ? '' : 'desde '}
                  {availability?.total}
                </Total>
              </Indicator>
            </Info>
            <Button className={availability?.canInstantBooking ? 'instant-book-btn' : 'check-price-btn'} fullWidth onClick={() => handleSubmit(values)}>
              {availability?.canInstantBooking
                ? 'Reserva Inmediata'
                : 'Consulta precio y disponibilidad'}
            </Button>
          </>
        )}

        {availability?.message ? (
          <Message>{availability?.message}</Message>
        ) : null}
        {showPriceAndConditions && (
          <Link href="#price-and-conditions">Ver precio y condiciones</Link>
        )}
        {availability?.available && <Message>Impuestos includos.</Message>}
      </Wrapper>
    </Container>
  );
};

export default BookingCard;

import {Link, Redirect, Route, Switch} from 'react-router-dom';

import Booking from 'containers/Private/Booking';
import Bookings from 'containers/Private/Bookings';
import Cities from 'containers/Open/Cities';
import CancellationOptions from 'containers/Open/CancellationOptions';
import Favorites from 'containers/Private/Favorites';
import Footer from 'containers/Shared/Footer';
import Help from 'containers/Open/Help';
import SendSpace from 'containers/Open/SendSpace';
import Home from 'containers/Open/Home';
import Privacy from 'containers/Open/Privacy';
import TermsAndConditions from 'containers/Open/TermsAndConditions';
import LoginRedirect from 'containers/Shared/LoginRedirect';
import PrivateRoute from 'components/PrivateRoute';
import Profile from 'containers/Private/Profile';
import React from 'react';
import Search from 'containers/Open/Search';
import Spaces from 'containers/Open/Spaces';
import Topbar from 'containers/Shared/Topbar';
import Cookies from 'containers/Open/Cookies';
import PaymentOptions from 'containers/Open/PaymentOptions';
import ResetPassword from 'containers/Open/ResetPassword';
import useConnect from './connect';
import WhatItsSedee from "../Open/WhatItsSedee";
import CancellationPolicies from "../Open/ CancellationPolicies";
import GeneralTerms from "../Open/GeneralTerms";
import HowSedeeWorks from "../Open/HowSeedeWorks";
import CookieConsent from "react-cookie-consent";
import RequestSpace from "../Open/RequestSpace";
import MobileFooter from "../Shared/MobileFooter/MobileFooter";

const Root = () => {
  const { loading, isAuth } = useConnect();

  if (loading) return null;

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Topbar isAuth={isAuth} allowSearch />
          <Home />
        </Route>
        <Route exact path="/reset-password">
          <Topbar isAuth={isAuth} />
          <ResetPassword />
        </Route>
        <Route exact path="/cities">
          <Topbar isAuth={isAuth} allowSearch />
          <Cities />
        </Route>
        <Route exact path="/search">
          <Topbar isAuth={isAuth} allowSearch wide />
          <Search />
        </Route>
        <Route exact path="/spaces/:spaceSlug">
          <Topbar isAuth={isAuth} />
          <Spaces />
        </Route>
        <Route exact path="/send-space">
          <Topbar isAuth={isAuth} />
          <SendSpace />
        </Route>
        <Route exact path="/request-space">
          <Topbar isAuth={isAuth} />
          <RequestSpace />
        </Route>
        <Route component={Help} exact path="/help">
          <Topbar isAuth={isAuth} />
          <Help />
        </Route>
        <Route exact path="/cancellation-options">
          <Topbar isAuth={isAuth} />
          <CancellationOptions />
        </Route>
        <Route exact path="/cancellation-policies">
          <Topbar isAuth={isAuth} />
          <CancellationPolicies />
        </Route>
        <Route exact path="/cookies">
          <Topbar isAuth={isAuth} />
          <Cookies />
        </Route>
        <Route exact path="/que-es-sedee">
          <Topbar isAuth={isAuth} />
          <WhatItsSedee />
        </Route>
        <Route exact path="/how-sedee-works">
          <Topbar isAuth={isAuth} />
          <HowSedeeWorks/>
        </Route>
        <Route exact path="/payment-options">
          <Topbar isAuth={isAuth} />
          <PaymentOptions />
        </Route>
        <Route exact path="/privacy">
          <Topbar isAuth={isAuth} />
          <Privacy />
        </Route>
        <Route exact path="/terms-and-conditions">
          <Topbar isAuth={isAuth} />
          <TermsAndConditions />
        </Route>
        <Route exact path="/general-terms">
          <Topbar isAuth={isAuth} />
          <GeneralTerms />
        </Route>
        <PrivateRoute
          component={Bookings}
          exact
          path="/bookings"
          isAuthenticated={isAuth}
        />
        <PrivateRoute
          component={Favorites}
          exact
          path="/favorites"
          isAuthenticated={isAuth}
        />
        <PrivateRoute
          exact
          path="/bookings/:bookingId"
          isAuthenticated={isAuth}
        >
          <Topbar isAuth />
          <Booking />
        </PrivateRoute>
        <PrivateRoute
          component={Profile}
          path="/profile"
          isAuthenticated={isAuth}
        />
        <Route
          exact
          component={LoginRedirect}
          path="/connect/:providerName/callback"
        />
        <Redirect to="/" />
      </Switch>
      <CookieConsent
        location="bottom"
        buttonText="Aceptar"
        declineButtonText="Rechazar"
        enableDeclineButton={true}
        style={{ background: "#4640DF", boxShadow: '0px 2px 15px rgba(255,255,255,0.4)', zIndex: 9999 }}
        declineButtonStyle={{
          color: "white",
          border: '0.0625rem solid #FFFFFF',
          borderRadius: '0.3125rem',
          fontSize: "0.875rem",
          background: 'transparent',
          fontWeight: 600,
        }}
        buttonStyle={{
          color: "white",
          border: '0.0625rem solid #FFFFFF',
          borderRadius: '0.3125rem',
          fontSize: "0.875rem",
          background: 'transparent',
          fontWeight: 600,
      }}
        expires={150}
      >
        <h1 style={{ fontSize: '25px', paddingBottom: '10px', paddingTop: '15px' }}>Configuración de cookies</h1>
        <div style={{ fontSize: '0.9rem', paddingBottom: '15px' }}>
          Las cookies son importantes para ti, influyen en tu experiencia de navegación. Usamos cookies analíticas y de
          personalización para que puedas gestionar tus reservas. Para más información, consulta nuestra &nbsp;
          <Link to="/cookies" style={{ color: 'white' }}>Política de Cookies</Link>.
        </div>
      </CookieConsent>

      <MobileFooter />

      <Footer />
    </>
  );
};

export default Root;

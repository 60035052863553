import { from, useMediaQuery } from 'styles/media';
import { useModalContext } from 'provider/Modal';

const useConnect = () => {
  const isMobile = !useMediaQuery(from.tablet);
  const {
    open,
    handleCloseModal,
    handleOpenModalLogin,
    handleOpenModalRegister,
    handleOpenModalForgot,
    handleOpenModalCallback,
  } = useModalContext();

  return {
    isMobile,
    open,
    handleCloseModal,
    handleOpenModal: handleOpenModalLogin,
    handleOpenModalRegister,
    handleOpenModalForgot,
    handleOpenModalCallback
  };
};

export default useConnect;

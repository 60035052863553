import React from 'react';
import useConnect from './connect';
import {
  Container,
  Header,
  Title,
  ButtonBack,
  InfoText,
  Content,
} from './styles';
import {Subtitle} from "../Search/styles";
import {Li, Ul} from "../PaymentOptions/styles";
import {Link} from "../Home/styles";
import Button from "../../../components/Button";
import { resetCookieConsentValue } from "react-cookie-consent";

const CookiePolicy = () => {
  const {handleGoBack} = useConnect();

  const resetCookies = () => {
    resetCookieConsentValue();
    window.location.reload();
  }

  return (
    <Container>
      <Header>
        <ButtonBack onClick={handleGoBack}/>
        <Title>POLÍTICA DE COOKIES</Title>
      </Header>
      <Content>
        <Button type="button" style={{ marginBottom: '30px' }} onClick={() => resetCookies()}>
          Cambiar configuración
        </Button>
        <InfoText>
          El objetivo de esta política es informar a los interesados acerca de las cookies que emplea esta página web de conformidad con lo establecido en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, y el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016.
        </InfoText>
      </Content>
      <Content>
        <Title>
          1. USO DE COOKIES. ¿QUÉ SON LAS COOKIES?
        </Title>
        <InfoText>
          Las cookies son ficheros que se descargan en su Ordenador, Smartphone o Tablet al acceder a determinadas páginas web. La utilización de cookies, ofrece numerosas ventajas en la prestación de servicios de la Sociedad de la Información, puesto que, entre otras: (a) facilita la navegación del usuario en el Sitio Web; (b) facilita al usuario el acceso a los diferentes servicios que ofrece el Sitio Web; (c) evita al usuario volver a configurar características generales predefinidas cada vez que accede al Sitio Web; (d) favorece la mejora del funcionamiento y de los servicios prestados a través del Sitio Web, tras el correspondiente análisis de la información obtenida a través de las cookies instaladas; (e) permiten a un Sitio Web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.
        </InfoText>
        <InfoText>
          La mayoría de los navegadores aceptan como estándar a las cookies y, con independencia de las mismas, permiten o impiden en los ajustes de seguridad las cookies temporales o memorizadas.
        </InfoText>
        <InfoText>
          Tenga en cuenta que para poder utilizar y contar con una mejor experiencia de navegación, es necesario que tenga habilitadas las cookies, especialmente aquellas de carácter técnico que resultan necesarias para que pueda identificarle como usuario registrado cada vez que acceda a la presente web.
        </InfoText>
        <InfoText>
          En caso de no querer recibir cookies, por favor, configure su navegador de internet, para que las borre del disco duro de su ordenador, las bloquee o le avise en su caso de instalación de las mismas.
        </InfoText>
      </Content>
      <Content>
        <Title>
          2. ¿QUÉ TIPOS DE COOKIES UTILIZA ESTA PÁGINA WEB?
        </Title>
        <Subtitle>
          Cookies utilizadas en este sitio web:
        </Subtitle>
        <InfoText>
          Siguiendo las directrices de la Agencia Española de Protección de Datos, procedemos a detallar el uso de las cookies que esta página web emplea, con el fin de proporcionarle la máxima información posible.
        </InfoText>
        <Subtitle>
          Cookies Propias:
        </Subtitle>
        <InfoText>
          Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario. No se utilizan cookies propias en este sitio web.
        </InfoText>
        <Subtitle>
          Cookies de Terceros:
        </Subtitle>
        <InfoText>
          Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.
        </InfoText>
        <InfoText>
          Selección de Espacios para Eventos S.L. no se hace responsable, en ningún caso, ni del contenido ni de la veracidad de las políticas y/o condiciones de uso y privacidad de los terceros incluidos a través de los enlaces en esta política.
        </InfoText>
      </Content>
      <Content>
        <Title>
          3. CONSENTIMIENTO
        </Title>
        <InfoText>
          En relación con la utilización de cookies de este sitio web, el usuario autoriza y consiente su uso de la siguiente forma:
        </InfoText>
        <InfoText>
          Cuando el usuario accede a cualquier página de la web, verá un aviso donde se indica que la página web de Selección de Espacios para Eventos S.L. utiliza cookies, pudiendo el usuario aceptar o rechazar el uso de las mismas a través de la configuración de su navegador. Al hacer clic en aceptar, usted acepta el uso de nuestras cookies y, en su caso, las de los terceros. El panel de configuración/privacidad le permite la posibilidad de aceptarlas por tipología.
        </InfoText>
      </Content>
      <Content>
        <Title>
          4. PLAZOS O CRITERIOS DE CONSERVACIÓN DE LOS DATOS.
        </Title>
        <InfoText>
          Los datos obtenidos a través del uso de las cookies por usted seleccionadas, serán conservados durante el tiempo necesario para cumplir con las finalidades para los que fueron recopilados y durante el periodo de tiempo establecido en el apartado 2 de la presente política, para cada una de las cookies instaladas en el dispositivo.
        </InfoText>
        <InfoText>
          Puede obtener información adicional sobre el tratamiento de sus datos personales, incluyendo su plazo de conservación, acudiendo a la Política de Privacidad.
        </InfoText>
      </Content>
      <Content>
        <Title>
          5. CÓMO BLOQUEAR REVOCAR O ELIMINAR LAS COOKIES INSTALADAS
        </Title>
        <InfoText>
          Puede usted permitir, bloquear, revocar o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones de su navegador. Puede encontrar información sobre cómo hacerlo, en relación con los navegadores más comunes, en los links que se incluyen a continuación:
        </InfoText>
        <Ul>
          <Li>
            Explorer: <Link to="https://support.microsoft.com/es-es/topic/c%C3%B3mo-eliminar-archivos-de-cookies-en-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc"></Link>
          </Li>

          <Li>
            Microsoft Edge: <Link to="https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"></Link>
          </Li>

          <Li>
            Chrome: <Link to="https://support.google.com/chrome/answer/95647?hl=es"></Link>
          </Li>

          <Li>
            Firefox: <Link to="https://support.mozilla.org/es/kb/Borrar%20cookies"></Link>
          </Li>

          <Li>
            Safari: <Link to="https://support.apple.com/es-es/guide/safari/sfri11471/mac"></Link>
          </Li>

          <Li>
            Opera: <Link to="https://help.opera.com/en/latest/web-preferences/#cookies"></Link>
          </Li>
        </Ul>
        <InfoText>
          Le informamos, no obstante, de la posibilidad de que la desactivación de alguna cookie impida o dificulte la navegación o la prestación de los servicios ofrecidos en la página web.
        </InfoText>
        <InfoText>
          Para poder revocar al cookies inicialmente aceptadas, debe hacer clic en el botón: “revocar cookies”.
        </InfoText>
      </Content>
      <Content>
        <Title>
          6. DESTINATARIOS DE LA INFORMACIÓN
        </Title>
        <InfoText>
          Tendrán acceso a la información obtenida a través de las cookies del sitio web las empresas colaboradoras detalladas en el Panel de Configuración, donde podrás asimismo consultar las finalidades con las que la utilizan y sus respectivas políticas de privacidad.
        </InfoText>
        <InfoText>
          Respecto a las cookies de Google instaladas en su navegador, puede consultar más información sobre el tratamiento de sus datos personales en su política de privacidad, accediendo al siguiente enlace: https://policies.google.com/technologies/partner-sites
        </InfoText>
        <InfoText>
          Asimismo, te informamos de que algunos de nuestros proveedores así como empresas colaboradoras pueden realizar transferencias a terceros países. Puedes informarte de las transferencias a terceros países que, en su caso, realizan en sus correspondientes políticas de privacidad, accesibles a través de los enlaces facilitados en el Panel de Configuración.
        </InfoText>
      </Content>
      <Content>
        <Title>
          7. MODIFICACIONES/ ACTUALIZACIÓN
        </Title>
        <InfoText>
          La presente política de cookies puede verse modificada/actualizada en función de las exigencias legales establecidas o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos o cambios en nuestro sitio web.
        </InfoText>
        <InfoText>
          Por esta razón, aconsejamos a los usuarios que visiten periódicamente nuestra política de cookies.
        </InfoText>
        <InfoText>
          Si tiene dudas acerca de esta política de cookies, puede contactar con Sedee. a través del siguiente correo electrónico: info@sedee.io
        </InfoText>
        <InfoText>
          Puede acudir a la Política de Privacidad para obtener más información sobre el tratamiento de sus datos personales mediante el uso de cookies.
        </InfoText>
      </Content>
    </Container>
  );
};

export default CookiePolicy;

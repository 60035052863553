import styled from 'styled-components';
import MaxContent from 'components/MaxContent';
import { PSemi, Small } from 'components/Typography';
import { Link as DefaultLink } from 'react-router-dom';
import { FiClock, FiInstagram, FiMail, FiPhone } from 'react-icons/fi';
import { AiFillLinkedin, AiOutlineFacebook } from 'react-icons/ai';
import { ReactComponent as DefaultLogo } from 'assets/brand/logoBlack.svg';
import { from } from 'styles/media';

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray200};
  padding: 1rem;
`;


export const Content = styled(MaxContent)`
  padding-top: 1.5rem;
  padding-bottom: 4rem;
  ${from.tablet} {
    padding-top: 3.375rem;
    padding-bottom: 5rem;
  }
`;

export const SocialLink = styled.a``;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${from.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.25rem;
  }
`;

export const Link = styled(DefaultLink)`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.225rem;
  color: ${({ theme }) => theme.colors.ebonyClay};
  margin-bottom: 1.25rem;
  text-decoration: none;
  line-height: 0.625rem;
`;

export const Anchor = styled.a`
  font-weight: 400;
  line-height: 1.225rem;
  font-size: 0.875rem;
  line-height: 1.225rem;
  color: ${({ theme }) => theme.colors.ebonyClay};
  text-decoration: none;
`;

export const Text = styled(Small)`
  line-height: 1.3125rem;
  font-size: 0.75rem;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  gap: 0.625rem;
  align-items: center;
  margin-bottom: 0.9375rem;
`;

export const Title = styled(PSemi)`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  ${from.tablet} {
    margin-top: 0;
  }
`;
export const TitleContact = styled(PSemi)`
  margin-bottom: 1.5rem;
  ${from.tablet} {
    margin-top: 0;
    margin-bottom: 0.625rem;
  }
`;

export const IconMail = styled(FiMail)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${({ theme }) => theme.colors.gray700};
`;

export const IconPhone = styled(FiPhone)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${({ theme }) => theme.colors.gray700};
`;

export const IconClock = styled(FiClock)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${({ theme }) => theme.colors.gray700};
`;

export const IconGroup = styled.div`
  display: flex;
  margin-bottom: 1.0rem;
  margin-top: 0.625rem;
`;

export const IconInstagram = styled(FiInstagram)`
  width: 1.25rem;
  height: 1.25rem;
  color: ${({ theme }) => theme.colors.ebonyClay};
  margin-right: 1.25rem;
`;

export const IconFacebook = styled(AiOutlineFacebook)`
  width: 1.25rem;
  height: 1.25rem;
  color: ${({ theme }) => theme.colors.ebonyClay};
  margin-right: 1.25rem;
`;

export const IconLinkedin = styled(AiFillLinkedin)`
  width: 1.25rem;
  height: 1.25rem;
  color: ${({ theme }) => theme.colors.ebonyClay};
`;

export const Logo = styled(DefaultLogo)`
  width: 5.5rem;
  height: auto;
  color: ${({ theme }) => theme.colors.ebonyClay};
  margin-bottom: 0.625rem;
`;

export const Line = styled.div`
  width: 100%;
  height: 0.0625rem;
  background-color: ${({ theme }) => theme.colors.gray400};
  margin-top: 3rem;
  margin-bottom: 2rem;

  ${from.tablet} {
    background-color: ${({ theme }) => theme.colors.gray500};
    margin-top: 2.625rem;
  }
`;

export const LegalSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${from.tablet} {
    flex-direction: row;
  }
`;

export const LegalLink = styled(DefaultLink)`
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.ebonyClay};
  text-decoration: none;
  text-align: center;
  :last-child {
    margin-right: 0;
  }
  ${from.tablet} {
    margin-right: 1.75rem;
  }
`;

export const LastLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  ${from.tablet} {
    display: flex;
    gap: 0;
  }
`;

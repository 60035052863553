import { Body, BodyMedium, Caption, H5, PSemi } from 'components/Typography';

import DefaultButton from 'components/Button';
import DefaultDatePicker from 'components/DatePicker';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 25.6875rem;
  width: 100%;
  margin-left: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.5rem 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0rem 0.3125rem 1.25rem rgba(51, 46, 191, 0.2);
  border-radius: 0.3125rem;
  position: sticky;
  top: 7.5rem;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`;

export const Title = styled(H5)`
  color: ${({ theme }) => theme.colors.ebonyClay};
`;

export const PricePerDay = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const PerDay = styled(Caption)`
  margin-left: 0.3125rem;
  margin-bottom: 0.27rem;
  color: ${({ theme }) => theme.colors.gray500};
`;

export const Message = styled(Caption)`
  text-align: center;
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const DatePicker = styled(DefaultDatePicker)`
  width: 100%;
  margin-bottom: 2.625rem;
`;

export const Info = styled.div`
  width: 100%;
  margin-bottom: 0.9375rem;
`;

export const Indicator = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const Content = styled(Body)`
  color: ${({ theme }) => theme.colors.gray700};
`;

export const Quantity = styled(BodyMedium)`
  color: ${({ theme }) => theme.colors.gray700};
`;

export const Divider = styled.span`
  display: block;
  width: 100%;
  height: 0.0625rem;
  background-color: ${({ theme }) => theme.colors.gray300};
  margin-bottom: 1rem;
`;

export const Total = styled(PSemi)`
  color: ${({ theme }) => theme.colors.ebonyClay};
`;

export const Button = styled(DefaultButton)`
  display: block;
`;

export const Link = styled.a`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.0625rem;
  text-align: center;
  margin-top: 1rem;
  color: #16181a;
`;

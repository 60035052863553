import * as Yup from 'yup';

export const initialValues = {
  name: '',
  phone: '',
  check: false,
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es obligatorio'),
  phone: Yup.string().required('El teléfono es obligatorio'),
  check: Yup.boolean().oneOf([true], 'Debes aceptar los términos y condiciones'),
});

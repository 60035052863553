import React, {FC, useState} from 'react';
import {
    Section, SectionLi, SectionTitle, SectionUl, Strong, SectionText, Container, TransferButton
} from './styles';
import { Props } from './types';
import Dialog from "@material-ui/core/Dialog";
import {Button, CloseIcon, Header, ModalContent} from "../../../../components/Modal/styles";
import {ModalSection} from "../../../Private/Booking/styles";
const TransferModal: FC<Props> = ({bookingId}) => {
  const [itsOpen, setItsOpen] = useState(false);
  const handleClose = () =>{
    setItsOpen(false)
  }
  const handleClick = () =>{
    setItsOpen(true)
  }
  return (
      <Container>
      <TransferButton onClick={handleClick}>
        ¿Como realizo la Transferencia?
      </TransferButton>
      { itsOpen && (
          <ModalSection>
              <ModalContent className="modal-ui">
                  <Dialog
                      open={itsOpen}
                      onClose={handleClose}
                  >
                      <Header>
                          <Button type="button" onClick={handleClose}>
                              <CloseIcon />
                          </Button>
                      </Header>
                      <Section>
                          <SectionTitle>
                              1. Datos de transferencia
                          </SectionTitle>
                          <SectionText>
                              Datos necesarios para hacer la transferencia
                          </SectionText>
                          <SectionUl>
                              <SectionLi>
                                  Titular de cuenta: <Strong>Selección de Espacios para Eventos S.L.</Strong>
                              </SectionLi>
                              <SectionLi>
                                  Nº de cuenta: <Strong>ES82 0182 1600 2302 0193 1429</Strong>
                              </SectionLi>

                              <SectionLi>
                                Concepto: <Strong>Nº {bookingId}</Strong>
                              </SectionLi>
                          </SectionUl>
                      </Section>
                      <Section>
                          <SectionTitle>
                              2. Realiza la transferencia
                          </SectionTitle>
                          <SectionText>
                              Realiza la transferencia de la manera que prefieras.
                              Tienes 48h para realizar en pago del importe.
                          </SectionText>
                      </Section>
                      <Section>
                          <SectionTitle>
                              3. Envianos el comprobante
                          </SectionTitle>
                          <SectionText>
                              Envía el comprobante de pago a <Strong>transferencias@sedee.io</Strong> con tu
                              nombre y el número de reserva del espacio.
                          </SectionText>
                      </Section>
                  </Dialog>
              </ModalContent>
          </ModalSection>

        )}
      </Container>
  );
};

export default TransferModal;

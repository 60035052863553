import styled from 'styled-components';
import Modal from 'components/Modal';
import DefaultButton from 'components/Button';
import DefaultSocialButton from 'components/SocialButton';
import { Small } from 'components/Typography';
import DefaultInput from 'components/Input';
import {Link as DefaultLink, Link as DefaulLink} from 'react-router-dom';
import { FcGoogle as Icon } from 'react-icons/fc';
import { Form as FormikForm } from 'formik';
import DefaultCheckbox from 'components/Checkbox';
import { from } from 'styles/media';
import DefaultDivider from '../Divider';

export const Container = styled(Modal)`
`;

export const Body = styled.div`
  padding: 2rem 1rem 7.6875rem 1rem;

  ${from.mobile} {
    padding: 2.5rem;
  }
`;

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
`;

export const Button = styled(DefaultButton)`
  margin-top: 2.5rem;
  margin-bottom: 0.625rem;
`;

export const RegisteredButton = styled(DefaultButton)`
  background: none;
  border: none;
`;

export const SocialButton = styled(DefaultSocialButton)`
  margin-top: 0.625rem;
`;

export const Input = styled(DefaultInput)`
  :not(:first-child) {
    margin: 1rem 0;
  }
`;

export const Link = styled(DefaulLink)`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 140%;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.black};
  animation-timing-function: ease-out;
  animation-duration: 300ms;
`;

export const LoginButton = styled.button`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 140%;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.black};
  animation-timing-function: ease-out;
  animation-duration: 300ms;
  border: 0;
  background: none;
  padding: 0;
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
`;

export const Text = styled(Small)`
  margin-right: 0.3125rem;
`;

export const GoogleIcon = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
`;

export const Divider = styled(DefaultDivider)``;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const LegalText = styled.span`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.05rem;
  letter-spacing: 4%;
  max-width: 23.125rem;
`;

export const Checkbox = styled(DefaultCheckbox)`
  margin-right: 1rem;
`;

export const LegalLink = styled(DefaultLink)`
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.ebonyClay};
  text-decoration: none;
  text-align: center;
`;
